
import debug from 'debug';

const BASE = 'offshoot-admin';

class Log {
    generateMessage(level: string, message: any, source: string) {
        // Set the prefix which will cause debug to enable the message
        const namespace = `${BASE}:${level}`;
        const createDebug = debug(namespace);

        if(source) { createDebug(source, message); }
        else { createDebug(message); }
    }

    trace(message: string, source: string) {
        return this.generateMessage('trace', message, source);
    }

    info(message: string, source?: string) {
        return this.generateMessage('info', message, source || '');
    }

    warn(message: string, source: string) {
        return this.generateMessage('warn', message, source);
    }

    error(message: string, source?: string) {
        return this.generateMessage('error', message, source || '');
    }
}

export default new Log();